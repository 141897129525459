/* Navbar */
.nav-item {
  @apply text-neutral text-sm;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.nav-item::after {
  content: "";
  width: 0%;
  height: 2px;
  display: block;
  background-color: var(--app-color-light-gray);
  margin-top: 2px;
  transition: all 0.3s;
}

.nav-item:hover::after,
.nav-item.active::after {
  width: 100%;
  transition: all 0.3s;
}

/* Back drop */
.backdrop {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;
}

/* Side Drawer */
.sidedrawer {
  width: 65%;
  height: 100vh;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500; 
  transform: translateX(-100%);
  transition: all 0.3s ease-out;
}

.sidedrawer.show {
transform: translateX(0);
}

.sidedraw_links {
  display: flex;
  flex-direction: column;
  list-style: none; 
}

.sidedraw_links > li {
  display: flex;
  align-items: center;
}

.sidedraw_links > li > a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  padding: 0.6rem;
  text-decoration: none;
  color: #000;
  font-size: 1rem;
}

.sidedraw_links > li > a:hover {
 @apply text-primary;
}

.sidedraw_links a.active {
  @apply text-primary;
}

@media(min-width: 760px) {
  .sidedrawer {
     display: none;
  }
}

