@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

/*== Default ==*/

:root {
  --app-color-primary: #333296;
  --app-color-danger: #fc1725;
  --app-color-light-gray: #eeeeee;
}

html {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

body {
  font-family: "Roboto", sans-serif;
}

.Container {
  @apply container w-full mx-auto xl:px-28 md:px-6 lg:px-6 px-5;
}

.button {
  @apply border border-secondary rounded font-medium bg-secondary text-white transition ease-in-out duration-300 text-sm tracking-wider hover:opacity-70;
  padding: 6px 13px;
}

.transparentButton {
  @apply border border-slate-400 hover:text-secondary hover:border-slate-800 rounded font-medium bg-transparent transition ease-in-out duration-500 text-base tracking-wider;
  padding: 6px 13px;
}

/*== Home page ==*/
.hero {
  width: 100%;
  height: 91vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("./Images/hero.jpg");
}

@media (max-width: 768px) {
  .hero {
    height: 60vh;
  }
}

.home-table {
  @apply w-full table-auto border-collapse border border-slate-600 text-center;
}

.home-table th,
.home-table td {
  @apply border border-slate-600 p-2;
}

/*-- General Banner ---*/
.banner {
  height: 50vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.5) url("./Images/banner.jpg");
  background-blend-mode: overlay;
  background-position: center;
}

/*== footer ==*/
footer h3 {
  @apply font-semibold text-base mb-1 text-gray-200;
}

footer a {
  @apply hover:text-black text-sm;
}

/* Contact form */
.contact-form input, .payment-form input {
  @apply py-2 px-3 rounded-sm w-full focus:outline-none border text-gray-700 border-black placeholder:text-black;
}

/* about */
.about_content p {
  @apply pb-2;
}

.about-content-num b {
  @apply text-lg
}